import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

const BlueGradient = () => (
  <Box
    sx={{
      width: { xs: 487, lg: 1222 },
      height: { xs: 188, lg: 504 },
      position: 'absolute',
      top: { xs: -50, lg: -300 },
      left: { xs: -50, lg: -200 },
      zIndex: 1,

      '.react-reveal': {
        width: '100%',
        height: '100%'
      }
    }}
  >
    <Fade duration={300} delay={400} ssrReveal>
      <Box
        sx={{
          background:
            'linear-gradient(360deg, rgba(23, 211, 255, 0.7) 5.95%, rgba(0, 234, 144, 0) 88.89%)',
          filter: { xs: 'blur(27.9255px)', lg: 'blur(75px)' },
          width: '100%',
          height: '100%'
        }}
      />
    </Fade>
  </Box>
)

export default BlueGradient
