import React, { useState, createContext } from 'react'

import SwipeableViews from 'react-swipeable-views'

import { Box, IconButton, Stack } from '@mui/material'

import ArrowRightIcon from 'app/assets/icons/arrow-right.svg'
import ArrowRightWhiteIcon from 'app/assets/icons/landing/arrow-right-white.svg'
import { ACTION_STATUS } from 'app/utils/constants/status.constants'

interface VideoSliderProps {
  type: ActionType
}

const VideoSliderContext = createContext<{
  activeIndex: number
}>({
  activeIndex: 0
})

export const useVideoSliderContext = () => React.useContext(VideoSliderContext)

const VideoSlider: RFC<VideoSliderProps> = ({ type, children }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const isBuyer = type === ACTION_STATUS.buyer
  const arrayChildren = React.Children.toArray(children)

  const handleNextClick = (e) => {
    e.stopPropagation()
    setActiveIndex((prev) => {
      if (arrayChildren.length - prev === 1) {
        return 0
      }
      return prev + 1
    })
  }

  const handlePrevClick = (e) => {
    e.stopPropagation()
    setActiveIndex((prev) => {
      if (prev === 0) {
        return arrayChildren.length - 1
      }
      return prev - 1
    })
  }

  const buttonColor = isBuyer ? 'background.default' : 'primary.main'
  const buttonIcon = isBuyer ? <ArrowRightIcon /> : <ArrowRightWhiteIcon />

  return (
    <VideoSliderContext.Provider value={{ activeIndex }}>
      <Stack direction="column" spacing={2}>
        <SwipeableViews
          index={activeIndex}
          onChangeIndex={setActiveIndex}
          resistance
          enableMouseEvents
        >
          {arrayChildren.map((el, index) => (
            <Box key={index} sx={{ overflow: 'hidden' }}>
              {el}
            </Box>
          ))}
        </SwipeableViews>

        <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
          <IconButton
            onClick={handlePrevClick}
            sx={{
              transform: 'rotate(180deg)',
              backgroundColor: buttonColor,
              height: 40,
              width: 40
            }}
            disableRipple
          >
            {buttonIcon}
          </IconButton>

          <Stack direction="row" spacing={1.5} sx={{ alignItems: 'center' }}>
            {arrayChildren.map((item, index) => (
              <Box
                key={index}
                sx={{
                  height: 8,
                  width: 8,
                  borderRadius: '50%',
                  backgroundColor:
                    index === activeIndex ? 'secondary.main' : 'text.secondary'
                }}
              />
            ))}
          </Stack>

          <IconButton
            onClick={handleNextClick}
            sx={{
              backgroundColor: buttonColor,
              height: 40,
              width: 40
            }}
            disableRipple
          >
            {buttonIcon}
          </IconButton>
        </Stack>
      </Stack>
    </VideoSliderContext.Provider>
  )
}

export default VideoSlider
