import NextImage from 'next/image'
import React from 'react'

import { Box, Theme, useMediaQuery } from '@mui/material'

import ChatSrc from 'app/assets/images/landing/for-buyer/chat-bubble-desktop.png'
import ChatMobileSrc from 'app/assets/images/landing/for-buyer/chat-bubble-mobile.png'

const ChatImage = () => {
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('xl'),
    {
      noSsr: true
    }
  )

  const imageSrc = isMobile ? ChatMobileSrc : ChatSrc
  const imageWidth = isMobile ? 230 : 261
  const imageHeight = isMobile ? 81 : 112

  return (
    <Box
      sx={{
        position: 'relative',
        width: { xs: 230, xl: 261 },
        height: { xs: 81, xl: 112 },
        display: 'block',
        top: { xs: 35, md: 25, lg: 20, xl: 25, xxl: 0 },
        right: { xs: 25, xxl: 0 }
      }}
    >
      <NextImage
        src={imageSrc}
        sizes="384px"
        layout="responsive"
        width={imageWidth}
        height={imageHeight}
        alt="messages"
      />
    </Box>
  )
}

export default ChatImage
