import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import GreenEclipse from '~/views/LandingView/components/GreenEclipse'

const EclipseBlock = () => (
  <Box
    sx={{
      width: '50%',
      height: 433,
      position: 'absolute',
      display: { xs: 'none', md: 'block' },
      left: '25%',
      top: { md: 150, lg: 200, xxl: 300 },

      '.react-reveal': {
        width: '100%',
        height: '100%'
      }
    }}
  >
    <Fade duration={500} delay={1500} ssrReveal>
      <GreenEclipse
        sx={{
          width: '100%',
          height: '100%'
        }}
      />
    </Fade>
  </Box>
)

export default EclipseBlock
