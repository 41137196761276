import NextImage from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import AvatarSrc from 'app/assets/images/landing/community/big-bottom-avatar.png'

const BigAvatarBottom = () => (
  <Box
    sx={{
      position: 'absolute',
      display: { xs: 'none', lg: 'block' },
      bottom: 259,
      left: { lg: 230, xl: 217 }
    }}
  >
    <Fade duration={300} delay={500} ssrReveal>
      <NextImage
        src={AvatarSrc}
        layout="intrinsic"
        sizes="256px"
        width={130}
        height={130}
        alt="big-bottom-avatar"
      />
    </Fade>
  </Box>
)

export default BigAvatarBottom
