import NextImage from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box, Theme, useMediaQuery } from '@mui/material'

import PhoneSrc from 'app/assets/images/landing/get-started/phone-desktop.png'

const PhoneImage = () => {
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('lg'),
    { noSsr: true }
  )

  const width = isMobile ? 375 : 847
  const height = isMobile ? 160 : 315
  const sizes = isMobile ? '640px' : '1080px'

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width,
        mt: { xs: 12.5, lg: 14.5 }
      }}
    >
      <Fade duration={500} delay={600} ssrReveal>
        <NextImage
          src={PhoneSrc}
          layout="intrinsic"
          sizes={sizes}
          width={width}
          height={height}
          alt="phone"
        />
      </Fade>
    </Box>
  )
}

export default PhoneImage
