import { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch } from 'react-hook-form'

import useExecuteGoogleRecaptcha from 'app/hooks/useExecuteGoogleRecaptcha'
import useFormErrorHandler from 'app/hooks/useFormErrorHandler'
import useGetIPInformation from 'app/hooks/useGetIPInformation'
import useModal from 'app/hooks/useModal'
import Alert from 'app/services/Alert'
import { isErrorHandlerType } from 'app/services/ErrorHandler'
import { useCreateMarketingContactMutation } from 'app/services/Query/user/user.mutation'
import { generateAgentToken } from 'app/utils/helpers/functions.helpers'
import { CreateMarketingUserSchema } from 'app/validation/schemas/user.schemas'

import { CUSTOMER_TYPE } from '~/views/LandingView/helpers/landing.constants'

interface UseSignUpFormProps {
  isModal?: boolean
}

export const useSignUpForm = ({ isModal }: UseSignUpFormProps) => {
  const {
    open: openSuccessModal,
    hideModal: hideSuccessModal,
    showModal: showSuccessModal
  } = useModal()

  const methods = useForm<CreateMarketingContactFV>({
    defaultValues: {
      email: '',
      phone_number: '',
      interest_makes: [],
      user_business: '',
      customer_type: CUSTOMER_TYPE.private,
      is_read_term: false
    },
    resolver: yupResolver(CreateMarketingUserSchema),
    mode: 'all'
  })

  const executeGoogleRecaptcha = useExecuteGoogleRecaptcha()
  const handleFormError = useFormErrorHandler(methods.setError)
  const { mutateAsync } = useCreateMarketingContactMutation()
  const { data: ipLocation } = useGetIPInformation()

  const customerTypeField = useWatch({
    control: methods.control,
    name: 'customer_type'
  })

  const { isSubmitting, isSubmitSuccessful, isDirty } = methods.formState
  const isPrivate = customerTypeField === CUSTOMER_TYPE.private
  const showSuccessBlock = isSubmitSuccessful && isModal

  const onSubmit = async (values: CreateMarketingContactFV) => {
    try {
      if (!executeGoogleRecaptcha) throw new Error()

      const captchaToken = await executeGoogleRecaptcha('onSubmit')
      const agentToken = generateAgentToken()

      const { is_read_term, interest_makes, ...userValues } = values

      await mutateAsync({
        agent_token: agentToken,
        captcha_token: captchaToken,
        marketing_contact: {
          ...userValues,
          country: ipLocation?.country || '',
          city: ipLocation?.city,
          interest_makes: interest_makes?.join(', ')
        }
      })

      if (!isModal) {
        showSuccessModal()
      } else {
        Alert.success('Successfully sent')
      }
    } catch (error) {
      if (isErrorHandlerType(error)) handleFormError(error?.data)
    }
  }

  useEffect(() => {
    if (isPrivate) {
      methods.setValue('user_business', '')
    } else {
      methods.setValue('interest_makes', [])
    }
  }, [isPrivate])

  return {
    methods,
    onSubmit,
    showSuccessBlock,
    isSubmitting,
    isDirty,
    hideSuccessModal,
    showSuccessModal,
    openSuccessModal,
    isPrivate
  }
}
