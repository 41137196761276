import NextImage from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import PhoneLeftSrc from 'app/assets/images/landing/hero-phone-left.png'

const PhoneLeft = () => (
  <Box
    sx={{
      position: 'absolute',
      width: { xs: 246, md: 250, xxl: 338 },
      top: { xs: 40, md: 0, lg: 50, xl: 110 },
      left: { xs: -60, md: 50, lg: 80, xl: 30, xxl: 210 },
      height: { xs: 431, md: 580 }
    }}
  >
    <Fade duration={700} delay={1200} bottom ssrReveal distance="50px">
      <NextImage
        src={PhoneLeftSrc}
        layout="intrinsic"
        sizes="400px"
        width={338}
        height={580}
        priority
        alt="phone"
      />
    </Fade>
  </Box>
)

export default PhoneLeft
