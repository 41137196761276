import dynamic from 'next/dynamic'
import React, { useEffect, useRef } from 'react'

import useSignUpModalContext from '~/views/LandingView/hooks/useSignUpModalContext'

const Dialog = dynamic(() => import('./SignUpDialog'), { ssr: false })

const SignUpModalBlock = () => {
  const { open, showModal, hideModal } = useSignUpModalContext()
  const mountedRef = useRef(false)

  if (!mountedRef.current && open) {
    mountedRef.current = open
  }

  useEffect(() => {
    const timer = setTimeout(() => showModal(), 30000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  if (!mountedRef.current) return null

  return <Dialog open={open} onClose={hideModal} />
}

export default SignUpModalBlock
