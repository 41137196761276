import NextImage from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import AvatarSrc from 'app/assets/images/landing/community/big-left-avatar.png'

const BigAvatarLeft = () => (
  <Box
    sx={{
      position: 'absolute',
      display: { xs: 'none', lg: 'block' },
      top: 347,
      height: 100,
      left: { lg: 32, xl: 100, xxl: 160 }
    }}
  >
    <Fade duration={300} delay={500} ssrReveal>
      <NextImage
        src={AvatarSrc}
        layout="intrinsic"
        sizes="256px"
        width={100}
        height={100}
        alt="big-left-avatar"
      />
    </Fade>
  </Box>
)

export default BigAvatarLeft
