import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box, Stack, StackProps } from '@mui/material'

import Text, { TextProps } from 'app/components/base/Text'

interface SectionHeaderProps {
  title: string
  description: string
  textColor?: TextProps['color']
  sx?: StackProps['sx']
  delay?: number
}

const StaticText = ({ title, description, textColor }) => (
  <Stack direction="column" spacing={2}>
    <Text
      variant="h2"
      sx={{
        color: textColor,
        textAlign: 'center',
        fontSize: { xs: 36, xxl: 64 },
        lineHeight: { xs: '49px', xxl: '74px' },
        zIndex: 1
      }}
    >
      {title}
    </Text>

    <Text
      variant="body1"
      sx={{
        color: textColor,
        textAlign: 'center',
        fontSize: { xs: 18, xxl: 24 },
        lineHeight: { xs: '27px', xxl: '34px' },
        whiteSpace: { md: 'pre-line' }
      }}
    >
      {description}
    </Text>
  </Stack>
)

const SectionHeader: RFC<SectionHeaderProps> = ({
  title,
  description,
  textColor = 'background.default',
  sx = {},
  delay = 0
}) => {
  return (
    <Box sx={sx}>
      <Fade duration={300} delay={delay} bottom ssrReveal distance="20px">
        <StaticText
          title={title}
          description={description}
          textColor={textColor}
        />
      </Fade>
    </Box>
  )
}

export default SectionHeader
