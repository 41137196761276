import React from 'react'

import { Box } from '@mui/material'

import IFindSVG from 'app/assets/images/landing/hero-ifnd.svg'

const IfndImage = () => (
  <Box
    sx={{
      position: 'absolute',
      width: { xs: 300, md: 425, lg: 450, xl: 400, xxl: 621 },
      height: { xs: 130, md: 175, lg: 200, xl: 175, xxl: 257 },
      bottom: { xs: 0, md: -15, lg: 110, xl: 160, xxl: 135 },
      left: { xs: 28, md: 30, lg: 110, xl: 45, xxl: 140 },
      // fadeInOut animation
      opacity: 0,
      transform: 'scale(0.75)',
      '@keyframes fadeInOut': {
        '0%': {
          opacity: 0,
          transform: 'scale(0.75)'
        },
        '50%': {
          opacity: 1,
          transform: 'scale(0.9)'
        },
        '100%': {
          opacity: 0,
          transform: 'scale(0.75)'
        }
      },
      animationFillMode: 'both',
      animationName: 'fadeInOut',
      animationDuration: '1.2s',
      animationDelay: '1s'
    }}
  >
    <IFindSVG />
  </Box>
)

export default IfndImage
