import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react'

import { CountryCode } from 'libphonenumber-js/types'
import PhoneInput from 'react-phone-number-input'

import useGetIPInformation from 'app/hooks/useGetIPInformation'
import useUserAddress from 'app/hooks/useUserAddress'

import TextInput, { TextInputProps } from '../TextInput'

import 'react-phone-number-input/style.css'
import { getPhonePlaceholder } from './phoneNumberInput.helpers'

export interface PhoneNumberInputProps extends TextInputProps {}

const TextInputNumber: RFC<PhoneNumberInputProps> = ({
  onChange,
  ...props
}) => {
  return (
    <TextInput
      {...props}
      sx={{
        '.PhoneInputInput': {
          border: 'none',
          fontFamily: 'Poppins',
          fontSize: 15,
          outline: 'none',
          color: 'primary.dark',
          fontWeight: 300
        },
        '.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border': {
          boxShadow: '0 0 0 #000'
        },
        '.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow':
          {
            color: 'primary.dark'
          },
        ...props?.sx
      }}
      onChange={onChange}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: PhoneNumberFormatCustom as any,
        ...props.InputProps
      }}
    />
  )
}

type Ref = HTMLInputElement

const PhoneNumberFormatCustom = forwardRef<Ref, PhoneNumberInputProps>(
  (props, ref) => {
    const [defaultCountry, setDefaultCountry] = useState<
      CountryCode | undefined
    >('AU')
    const { data: ipLocation } = useGetIPInformation()

    const showDefaultCountry = !!defaultCountry

    const placeholder = getPhonePlaceholder(defaultCountry as CountryCode)

    useEffect(() => {
      if (!!ipLocation?.country) {
        setDefaultCountry(ipLocation?.country as CountryCode)
      }
    }, [ipLocation?.country])

    return (
      <PhoneInput
        {...props}
        {...(showDefaultCountry && {
          defaultCountry
        })}
        international={false}
        addInternationalOption={false}
        initialValueFormat="national"
        onCountryChange={(country) => {
          setDefaultCountry(country)
        }}
        placeholder={placeholder}
        onChange={(value) => {
          const currentValue = (value ||
            '') as unknown as ChangeEvent<HTMLInputElement>

          if (props.onChange) props.onChange(currentValue)
        }}
        // @ts-ignore
        ref={ref}
      />
    )
  }
)

export default TextInputNumber
