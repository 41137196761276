import React from 'react'

import Fade from 'react-reveal/Fade'

import { Stack } from '@mui/material'

import Text from 'app/components/base/Text'

import { advantages } from '~/views/LandingView/helpers/landing.constants'

import AdvantageBlock from './AdvantageBlock'

const MemoAboutBlock = React.memo(() => (
  <>
    <Stack direction="column" spacing={2}>
      <Text
        variant="h1"
        sx={{ fontSize: { xs: 36, xxl: 64 }, lineHeight: { xxl: '74px' } }}
      >
        About the App
      </Text>

      <Text
        variant="body1"
        sx={{
          fontSize: { xs: 18, xxl: 24 },
          lineHeight: { xs: '27px', xxl: '34px' },
          b: { fontWeight: 500 }
        }}
      >
        We are a <b>specialised</b> <i>marketplace</i> dedicated for{' '}
        <b>CAR PARTS!</b> We’ve created an innovative auto parts shopping
        experience where you can <i>ifnd</i> the part you need, without the
        hassle.
      </Text>
    </Stack>

    {advantages.map((item) => (
      <AdvantageBlock
        key={item.title}
        title={item.title}
        description={item.description}
      />
    ))}
  </>
))

const AboutAppBlock = () => (
  <Fade duration={500} bottom ssrReveal distance="30px">
    <Stack
      direction="column"
      spacing={3}
      sx={{ maxWidth: { md: 450, xl: 752 } }}
    >
      <MemoAboutBlock />
    </Stack>
  </Fade>
)

export default AboutAppBlock
