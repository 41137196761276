import NextImage, { StaticImageData } from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box, BoxProps } from '@mui/material'

interface ChatBubbleProps {
  src: string | StaticImageData
  width: number
  height: number
  sx?: BoxProps['sx']
  delay?: string
}

const ChatBubble = ({
  src,
  width,
  height,
  sx,
  delay = '1.4s'
}: ChatBubbleProps) => (
  <Box
    sx={{
      ...sx,
      position: 'absolute',
      opacity: 0,
      transform: 'scale(1.5) rotate(-25deg)',
      '@keyframes chatBubble': {
        '0%': {
          opacity: 0,
          transform: 'scale(1.5) rotate(-25deg)'
        },
        '100%': {
          opacity: 1,
          transform: 'scale(1) rotate(0deg)'
        }
      },
      animationFillMode: 'forwards',
      animationName: 'chatBubble',
      animationDuration: delay,
      animationDelay: '0.5s'
    }}
  >
    <NextImage
      src={src}
      layout="intrinsic"
      sizes="384px"
      width={width}
      height={height}
      alt="chat-bubble"
    />
  </Box>
)

export default ChatBubble
