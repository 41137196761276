import NextImage from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import AvatarSrc from 'app/assets/images/landing/community/top-avatar-mobile.png'

const SmallAvatarTop = () => (
  <Box
    sx={{
      position: 'absolute',
      display: { xs: 'none', lg: 'block' },
      top: 244,
      height: 100,
      left: { xl: 590, xxl: 690 }
    }}
  >
    <Fade duration={300} delay={1100} ssrReveal>
      <NextImage
        src={AvatarSrc}
        layout="intrinsic"
        sizes="128px"
        width={100}
        height={100}
        alt="small-avatar-top"
      />
    </Fade>
  </Box>
)

export default SmallAvatarTop
