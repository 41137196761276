import React, { ReactElement, ReactNode, ReactText } from 'react'
import { Platform } from 'react-native'

import FormControl, { FormControlProps } from 'app/components/base/FormControl'
import Text from 'app/components/base/Text'
import Toggle, { ToggleProps } from 'app/components/base/Toggle'
import ToggleGroup, { ToggleGroupProps } from 'app/components/base/ToggleGroup'
import useFormField, { UseFormFieldProps } from 'app/hooks/useFormField'
import useOptions, { UseOptionsProps } from 'app/hooks/useOptions'
import { combineControllerProps } from 'app/utils/helpers/form.helpers'

import Stack from '../base/Stack'

export interface ToggleGroupFieldCustomProps extends UseFormFieldProps {
  helperText?: ReactText | ReactElement | ReactNode | null
  formControlProps?: FormControlProps
  items?: { label: string; value: string }[]
  label?: string
  children?: ReactNode
  orientation?: ToggleGroupProps['orientation']
  variant?: 'primary' | 'secondary'
  optionSettings?: UseOptionsProps
  itemSx?: ToggleProps['sx']
}

export type ToggleGroupFieldProps = ToggleGroupFieldCustomProps &
  Omit<ToggleGroupProps, 'value' | 'children' | 'onChange'>

const ToggleGroupField = ({
  helperText,
  formControlProps,
  items,
  optionSettings,
  children,
  label,
  orientation = 'horizontal',
  variant = 'primary',
  itemSx,
  ...props
}: ToggleGroupFieldProps) => {
  const { controllerProps, sx, ...rest } = combineControllerProps(props)
  const { error, onChange, value } = useFormField(controllerProps)

  const isPrimary = variant === 'primary'

  const { options } = useOptions({
    ...optionSettings,
    multiple: false,
    key: optionSettings?.key
  })

  const renderOptions = items || options

  return (
    <FormControl
      errorMessage={error}
      label={label}
      helperText={helperText}
      {...formControlProps}
    >
      <ToggleGroup
        onChange={onChange}
        value={value}
        {...rest}
        orientation={orientation}
        sx={{
          ...Platform.select({
            web: {
              flex: 1
            },
            native: {
              flex: 1,
              justifyContent: 'space-between'
            }
          }),
          ...sx
        }}
      >
        {!!renderOptions?.length &&
          renderOptions.map((item) => (
            <Toggle
              key={item.value}
              selected={value === item.value}
              variant={variant}
              size={!isPrimary ? 'medium' : 'small'}
              sx={{ ...itemSx }}
              {...item}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'center'
                }}
              >
                <Text
                  variant="body1"
                  sx={{
                    fontWeight: !isPrimary ? 500 : 300,
                    pl: isPrimary ? 0 : 1
                  }}
                >
                  {item.label}
                </Text>
              </Stack>
            </Toggle>
          ))}
      </ToggleGroup>
    </FormControl>
  )
}

export default ToggleGroupField
