import React from 'react'

import { Box, Stack } from '@mui/material'

import { palette } from 'app/theme/common/palette'

import SectionHeader from '~/views/LandingView/components/SectionHeader'
import VideoBlock from '~/views/LandingView/components/VideoBlock'
import { buyerVideos } from '~/views/LandingView/helpers/landing.constants'

import BottomEclipseBlock from './components/BottomEclipseBlock'
import BuyerChip from './components/BuyerChip'
import ForBuyerCards from './components/ForBuyerCards'
import TopEclipseBlock from './components/TopEclipseBlock'

const useStyles = () => {
  return {
    root: {
      background: `linear-gradient(180deg, ${palette.primary.main} 0%, ${palette.primary.dark} 100%)`,
      display: 'flex',
      overflow: 'hidden',
      position: 'relative'
    },
    wrapper: {
      px: { xs: 2, lg: 10, xxl: 25 },
      pt: { xs: 5, md: 9.75, lg: 18.75 },
      pb: { xs: 6.5, lg: 16 },
      alignItems: { xs: 'center' },
      width: '100%'
    }
  }
}

const ForBuyerSection = () => {
  const styles = useStyles()

  return (
    <Box id="forBuyer" sx={styles.root}>
      <Stack direction="column" sx={styles.wrapper}>
        <TopEclipseBlock />

        <Stack direction="column" spacing={3}>
          <BuyerChip />

          <SectionHeader
            title="We’ve Changed The Way You Buy"
            description={`Find, buy, and sell your auto parts in a dedicated marketplace app. \n Our innovative app helps you trade instantly.`}
            delay={700}
          />

          <Stack
            direction="column"
            spacing={{ xs: 5, md: 12.5 }}
            sx={{ zIndex: 1 }}
          >
            <VideoBlock videos={buyerVideos} type="buyer" />

            <ForBuyerCards />
          </Stack>

          <BottomEclipseBlock />
        </Stack>
      </Stack>
    </Box>
  )
}

export default ForBuyerSection
