import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import SignUpButton from '~/views/LandingView/components/SignUpButton'

const ButtonBlock = () => (
  <Box sx={{ zIndex: 2 }}>
    <Fade duration={300} delay={300} ssrReveal>
      <SignUpButton
        buttonProps={{
          size: 'large',
          color: 'primary',
          sx: { minHeight: 54, width: { xs: 280, md: 200 } }
        }}
      />
    </Fade>
  </Box>
)

export default ButtonBlock
