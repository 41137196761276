import React from 'react'

import { Paper, PaperProps, Stack, StackProps } from '@mui/material'

import Text from 'app/components/base/Text'
import { ACTION_STATUS } from 'app/utils/constants/status.constants'

interface AppCardProps {
  type?: ActionType
  title: string
  description: string
  contentDirection?: StackProps['direction']
  contentSx?: StackProps['sx']
  textStackSx?: StackProps['sx']
  sx?: PaperProps['sx']
}

const AppCard: RFC<AppCardProps> = ({
  type = 'buyer',
  title,
  description,
  contentDirection = 'column',
  contentSx = {},
  textStackSx = {},
  sx = {},
  children
}) => {
  const isBuyer = type === ACTION_STATUS.buyer

  const background = isBuyer
    ? 'linear-gradient(360deg, #111F5A 0%, #101340 100%)'
    : 'linear-gradient(180deg, #F0F5FF 0%, #F6F9FE 100%)'
  const border = isBuyer ? '1px solid #272D62' : '1px solid #D6DCE9'
  const textColor = isBuyer ? 'background.default' : 'primary.dark'

  return (
    <Paper
      elevation={0}
      sx={{
        background,
        borderRadius: 2,
        border,
        pt: 4,
        px: 6,
        overflow: 'hidden',
        position: 'relative',
        maxWidth: 615,
        width: '100%',
        height: { xs: '100%', lg: 310 },
        zIndex: 1,
        ...sx
      }}
    >
      <Stack
        direction={contentDirection}
        sx={{ alignItems: 'center', ...contentSx }}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{ alignItems: 'center', ...textStackSx }}
        >
          <Text
            variant="h2"
            sx={{
              fontSize: { xs: 24, md: 30 },
              color: textColor,
              textAlign: 'center'
            }}
          >
            {title}
          </Text>

          <Text
            variant="body1"
            sx={{
              color: textColor,
              textAlign: 'center',
              fontSize: { md: 18 },
              width: '100%'
            }}
          >
            {description}
          </Text>
        </Stack>

        {children}
      </Stack>
    </Paper>
  )
}

export default AppCard
