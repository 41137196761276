import React from 'react'

import { Box, Stack } from '@mui/material'

import SectionHeader from '~/views/LandingView/components/SectionHeader'
import VideoBlock from '~/views/LandingView/components/VideoBlock'
import { sellerVideos } from '~/views/LandingView/helpers/landing.constants'

import EclipseBlock from './components/EclipseBlock'
import ForSellerCards from './components/ForSellerCards'
import SellerChip from './components/SellerChip'

const ForSellerSection = () => {
  return (
    <Box
      id="forSeller"
      sx={{
        display: 'flex',
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      <Stack
        direction="column"
        sx={{
          px: { xs: 2, lg: 10, xxl: 25 },
          pt: { xs: 6, md: 9.75, lg: 18.75 },
          pb: { xs: 6, lg: 16 },
          alignItems: { xs: 'center' },
          width: '100%'
        }}
      >
        <Stack direction="column" spacing={2} sx={{ position: 'relative' }}>
          <SellerChip />

          <SectionHeader
            title="Sell Faster. Easier. Smarter"
            description={`Find, buy, and sell your auto parts in a dedicated marketplace app. \n Our innovative app helps you trade instantly.`}
            textColor="primary.dark"
            delay={300}
          />

          <EclipseBlock />

          <Stack
            direction="column"
            spacing={{ xs: 5, md: 12.5 }}
            sx={{ zIndex: 1 }}
          >
            <VideoBlock videos={sellerVideos} type="seller" />

            <ForSellerCards />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

export default ForSellerSection
