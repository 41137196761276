import Link from 'next/link'
import React from 'react'

import Text from 'app/components/base/Text'
import { menuItems } from 'app/utils/constants/menu.constants'

const ContactUsBlock = () => {
  return (
    <Text
      variant="body2"
      sx={{
        mt: 3,
        pb: { xs: 12, md: 0 },
        b: {
          fontWeight: 500,
          a: {
            textDecoration: 'none'
          },
          'a:hover': {
            color: 'secondary.dark'
          }
        }
      }}
    >
      Have a Questions?{' '}
      <b>
        <Link href={menuItems.contactUs.link}>Contact us</Link>
      </b>
    </Text>
  )
}

export default ContactUsBlock
