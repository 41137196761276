import dynamic from 'next/dynamic'
import React, { memo } from 'react'

import { FormProvider } from 'react-hook-form'

import { Stack } from '@mui/material'

import Button from 'app/components/base/Button'
import Text from 'app/components/base/Text'
import GooglePolicyTerms from 'app/components/common/GooglePolicyTerms'
import PhoneNumberField from 'app/components/fields/PhoneNumberField'
import SelectField from 'app/components/fields/SelectField'
import TextField from 'app/components/fields/TextField'
import ToggleGroupField from 'app/components/fields/ToggleGroupField'
import { getMakes } from 'app/services/Api/listing'
import { MAKE_SOURCE_TYPE } from 'app/utils/constants/listing.constants'

import {
  businessOptions,
  customerType
} from '~/views/LandingView/helpers/landing.constants'

import SuccessBlock from './components/SuccessBlock'
import { useSignUpForm } from './hooks/useSignUpForm'

const SuccessDialog = dynamic(() => import('./components/SuccessDialog'), {
  ssr: false
})

const privateOptionSettings = {
  key: 'makes',
  loadOptions: () => getMakes([MAKE_SOURCE_TYPE.imported]),
  optionValue: 'name',
  optionLabel: 'name'
}

const businessOptionSettings = {
  options: businessOptions
}

interface SignUpFormProps {
  isModal?: boolean
  closeModal?: () => void
}

const SignUpForm: RFC<SignUpFormProps> = ({
  isModal = false,
  closeModal = () => {}
}) => {
  const {
    methods,
    showSuccessBlock,
    openSuccessModal,
    isPrivate,
    isDirty,
    isSubmitting,
    hideSuccessModal,
    onSubmit
  } = useSignUpForm({
    isModal
  })

  if (showSuccessBlock) {
    return <SuccessBlock onClick={closeModal} />
  }

  return (
    <FormProvider {...methods}>
      <Stack direction="column" spacing={3}>
        <Stack direction="column" spacing={1}>
          <Text
            variant="h2"
            sx={{
              color: 'primary.dark',
              textAlign: 'center',
              maxWidth: { xs: 315, md: '100%' },
              display: { xs: 'flex', md: 'block' },
              alignSelf: { xs: 'center', md: 'auto' },
              fontSize: { xs: 24, md: 30 }
            }}
          >
            Register your interest now
            <br /> to Get Access First!
          </Text>

          <Text
            variant="body1"
            sx={{ color: 'primary.dark', textAlign: 'center' }}
          >
            We’ll let you know once we go live
          </Text>
        </Stack>

        <Stack direction="column" spacing={2} sx={{ alignItems: 'center' }}>
          <TextField
            name="email"
            label="Email"
            formControlProps={{
              sx: {
                maxWidth: 358,
                '.MuiFormLabel-root': { color: 'primary.dark' }
              }
            }}
          />

          <PhoneNumberField
            name="phone_number"
            label="Phone Number (optional)"
            formControlProps={{
              sx: {
                maxWidth: 358,
                '.MuiFormLabel-root': { color: 'primary.dark' }
              }
            }}
          />

          <Stack
            direction="column"
            spacing={1.5}
            sx={{ maxWidth: 358, width: '100%' }}
          >
            <Text variant="body1" color="text.third">
              What type of customer are you?
            </Text>

            <ToggleGroupField
              name="customer_type"
              items={customerType}
              itemSx={{ maxWidth: '100%', height: 54 }}
              formControlProps={{ sx: { maxWidth: 358 } }}
            />
          </Stack>

          {isPrivate && (
            <SelectField
              name="interest_makes"
              label="I’m Interested in"
              withFilter
              multiple
              optionSettings={privateOptionSettings}
              formControlProps={{ sx: { maxWidth: 358 } }}
            />
          )}

          {!isPrivate && (
            <SelectField
              name="user_business"
              label="My business is"
              optionSettings={businessOptionSettings}
              formControlProps={{ sx: { maxWidth: 358 } }}
            />
          )}
        </Stack>

        <Stack direction="column" spacing={1} sx={{ alignItems: 'center' }}>
          <Button
            variant="contained"
            disabled={!isDirty}
            loading={isSubmitting}
            onClick={methods.handleSubmit(onSubmit)}
            sx={{ height: 54, maxWidth: 358 }}
          >
            Register Now
          </Button>

          <GooglePolicyTerms />
        </Stack>

        {openSuccessModal && !isModal && (
          <SuccessDialog open={openSuccessModal} onClose={hideSuccessModal} />
        )}
      </Stack>
    </FormProvider>
  )
}

export default memo(SignUpForm)
