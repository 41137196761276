import React from 'react'

import LazyHydrate from 'react-lazy-hydration'

import { Stack } from '@mui/material'

import GoogleReCaptchaV3Provider from '~/providers/GoogleReCaptchaV3Provider'

import ScrollButton from './components/ScrollButton'
import SignUpModalBlock from './components/SignUpModalBlock'
import AboutAppSection from './sections/AboutAppSection'
import CommunitySection from './sections/CommunitySection'
import ForBuyerSection from './sections/ForBuyerSection'
import ForSellerSection from './sections/ForSellerSection'
import GetStartedSection from './sections/GetStartedSection'
import HeroSection from './sections/HeroSection'

const LandingView = () => {
  return (
    <GoogleReCaptchaV3Provider>
      <Stack direction="column">
        <HeroSection />

        <LazyHydrate whenVisible={{ threshold: 0, rootMargin: '-100px' }}>
          <AboutAppSection />
        </LazyHydrate>

        <LazyHydrate whenVisible={{ threshold: 0.25 }}>
          <ForBuyerSection />
        </LazyHydrate>

        {
          // TODO: Return CheckOurVideoSection when the client adds videos
        }

        <LazyHydrate whenVisible={{ threshold: 0.2 }}>
          <ForSellerSection />
        </LazyHydrate>

        <LazyHydrate whenVisible={{ threshold: 0.5 }}>
          <CommunitySection />
        </LazyHydrate>

        <LazyHydrate whenVisible={{ threshold: 0.3 }}>
          <GetStartedSection />
        </LazyHydrate>

        <SignUpModalBlock />

        <ScrollButton />
      </Stack>
    </GoogleReCaptchaV3Provider>
  )
}

export default LandingView
