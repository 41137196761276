import React from 'react'

import Zoom from 'react-reveal/Zoom'

import { Box } from '@mui/material'

import HeroGradient from './HeroGradient'

const Eclipse = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '1124px',
        height: '1091px',
        left: { xl: -300, xxl: -100 },
        transform: { xs: 'scale(0.6) rotate(-20deg)', md: 'scale(0.9)' },
        filter: { xs: 'blur(52px)', md: 'blur(65px)' },

        '.react-reveal': {
          position: 'absolute',
          width: '100%',
          height: '100%'
        }
      }}
    >
      <Zoom duration={700} ssrReveal>
        <HeroGradient />
      </Zoom>
    </Box>
  )
}

export default Eclipse
