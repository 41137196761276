import NextImage from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box, Stack } from '@mui/material'

import CreateListingSrc from 'app/assets/images/landing/for-seller/create-listing-phone.png'

import AppCard from '~/views/LandingView/components/AppCard'

import AuctionImage from './AuctionImage'
import StripeImage from './StripeImage'

const Cards = () => (
  <Stack
    direction={{ xs: 'column', lg: 'row-reverse' }}
    spacing={{ xs: 2, lg: 3.75 }}
    sx={{ alignItems: { xs: 'center' } }}
  >
    <AppCard
      type="seller"
      title="Create Listing"
      description="List it quickly, with our easy 3-step listing process."
      sx={{
        maxWidth: 487,
        minWidth: { xxl: 487 },
        height: { xs: 500, sm: 650 },
        maxHeight: '100%',
        pb: { xs: 31 }
      }}
    >
      <Box
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: -8,
          height: { xs: 288, sm: 'auto' }
        }}
      >
        <NextImage
          src={CreateListingSrc}
          sizes="480px"
          layout="intrinsic"
          width={487}
          height={412}
          alt="find"
        />
      </Box>
    </AppCard>

    <Stack
      direction="column"
      spacing={{ xs: 2, lg: 3.75 }}
      sx={{ alignItems: { xs: 'center' } }}
    >
      <Stack
        direction={{ xs: 'column', lg: 'row-reverse' }}
        spacing={{ xs: 2, xxl: 3.75 }}
        sx={{ alignItems: 'center', width: '100%' }}
      >
        <AppCard
          type="seller"
          title="Buy It Now"
          description="Buyers can instantly purchase your product. No phone calls, emails, follow ups. Simply connect your Stripe or PayPal and easily sell."
          sx={{
            pb: { xs: 13.5, lg: 6, xxl: 12.5 },
            pt: 4,
            flex: { lg: 1, xl: 2 },
            maxWidth: { xs: 487, lg: '100%', xxl: 615 },
            minWidth: { xxl: 615 },
            minHeight: { xs: 300, sm: 270, lg: 'auto' }
          }}
          textStackSx={{ maxWidth: { xxl: 283 } }}
          contentDirection={{ xs: 'column', xxl: 'row' }}
        >
          <StripeImage />
        </AppCard>

        <AppCard
          type="seller"
          title="Offers"
          description="Let the buyers come to you and make offers on your parts."
          sx={{
            pb: { xs: 6, xxl: 12.5 },
            pt: { xs: 6, lg: 4 },
            maxWidth: { xs: 487, lg: '100%', xxl: 357 },
            minWidth: { xxl: 357 },
            flex: { lg: 1 }
          }}
        />
      </Stack>

      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        spacing={{ xs: 2, xxl: 3.75 }}
      >
        <AppCard
          type="seller"
          title="Auctions"
          description="Start the bidding and have the potential to maximise your sale with our auction functionality."
          sx={{
            pb: { xs: 12.75, sm: 16 },
            flex: { lg: 1, xl: 2 },
            maxWidth: { xs: 487, lg: '100%', xxl: 615 }
          }}
        >
          <AuctionImage />
        </AppCard>

        <AppCard
          type="seller"
          title="Match Notifications"
          description="Buyers can get notified when your part is listed; helping you get it sold quicker."
          sx={{
            py: { xs: 7.5, lg: 4 },
            maxWidth: { xs: 487, lg: '100%', xxl: 357 },
            minWidth: { xxl: 357 },
            flex: { lg: 1 }
          }}
        />
      </Stack>
    </Stack>
  </Stack>
)

const ForSellerCards = () => {
  return (
    <Fade duration={500} delay={200} bottom ssrReveal distance="20px">
      <Cards />
    </Fade>
  )
}

export default ForSellerCards
