import React from 'react'

import { Box, Theme, useMediaQuery } from '@mui/material'

import ArrowDownFirstSVG from 'app/assets/icons/landing/hero-arrow-down-first.svg'
import ArrowDownSecondSVG from 'app/assets/icons/landing/hero-arrow-down-second.svg'

const ArrowDown = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  if (isMobile) {
    return null
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: 10
      }}
    >
      <Box
        sx={{
          '@keyframes fadeArrow': {
            '0%': {
              opacity: 0
            },
            '30%': {
              opacity: 1
            },
            '100%': {
              opacity: 1
            }
          },

          '.a-top-arrow': {
            opacity: 0,
            animationFillMode: 'forwards',
            animationName: 'fadeArrow',
            animationIterationCount: 'infinite',
            animationDuration: '2s',
            animationDelay: '2.2s'
          },
          '.a-bottom-arrow': {
            opacity: 0,
            animationFillMode: 'forwards',
            animationName: 'fadeArrow',
            animationIterationCount: 'infinite',
            animationDuration: '2s',
            animationDelay: '2.3s'
          }
        }}
      >
        <Box className="a-top-arrow" sx={{ height: 12 }}>
          <ArrowDownSecondSVG />
        </Box>

        <Box className="a-bottom-arrow">
          <ArrowDownFirstSVG />
        </Box>
      </Box>
    </Box>
  )
}

export default ArrowDown
