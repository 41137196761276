import React from 'react'

import { Box } from '@mui/material'

import { palette } from 'app/theme/common/palette'

import ImagesBlock from './components/ImagesBlock'
import TextBlock from './components/TextBlock'

const CommunitySection = () => {
  return (
    <Box
      sx={{
        background: `linear-gradient(180deg, ${palette.primary.main} 0%, ${palette.primary.dark} 100%)`,
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          px: { xs: 2, lg: 10, xl: 25 },
          pt: { xs: 28.25, lg: 61 },
          pb: { xs: 28.25, lg: 68 },
          position: 'relative',
          maxWidth: { xs: 487, lg: 1200, xl: 1322, xxl: 1520 },
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ImagesBlock />

        <TextBlock />
      </Box>
    </Box>
  )
}

export default CommunitySection
