import React from 'react'

import Fade from 'react-reveal/Fade'

import { Stack } from '@mui/material'

import Text from 'app/components/base/Text'
import { palette } from 'app/theme/common/palette'

const useStyles = () => {
  return {
    mainText: {
      fontSize: { xs: 56, md: 76, xxl: 96 },
      lineHeight: {
        xs: '58px',
        md: '79px',
        xxl: '100px'
      },
      color: 'background.default'
    },
    secondText: {
      fontSize: { xs: 56, md: 76, xxl: 96 },
      lineHeight: {
        xs: '58px',
        md: '79px',
        lg: '79px',
        xxl: '100px'
      },
      color: 'background.default'
    },
    thirdText: {
      fontSize: { xs: 56, md: 76, xxl: 96 },
      lineHeight: {
        xs: '58px',
        md: '79px',
        xxl: '100px'
      },
      background: `linear-gradient(90deg, ${palette.secondary.light} 0%, ${palette.secondary.main} 100%)`,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    }
  }
}

const MemoTitle = React.memo(() => {
  const styles = useStyles()

  return (
    <Stack
      direction="column"
      sx={{ alignItems: { xs: 'center', md: 'start' } }}
    >
      <Text variant="h1" sx={styles.mainText}>
        I Want It.
      </Text>

      <Text variant="h1" sx={styles.secondText}>
        I Need It.
      </Text>

      <Text variant="h1" sx={styles.thirdText}>
        ifnd It.
      </Text>
    </Stack>
  )
})

const TitleBlock = () => (
  <Fade duration={500} delay={1400} bottom ssrReveal distance="30px">
    <MemoTitle />
  </Fade>
)

export default TitleBlock
