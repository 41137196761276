import React from 'react'

import { Box, BoxProps } from '@mui/material'

const GreenEclipse = ({ sx = {}, ...props }: BoxProps) => {
  return (
    <Box
      sx={{
        background:
          'radial-gradient(60.57% 39.09% at 56.6% 68.91%, #00EA90 0%, rgba(0, 128, 200, 0) 89.72%)',
        filter: 'blur(65px)',
        ...sx
      }}
      {...props}
    />
  )
}

export default GreenEclipse
