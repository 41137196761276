import NextImage from 'next/image'
import React from 'react'

import { Box, Theme, useMediaQuery } from '@mui/material'

import StripeMobileSrc from 'app/assets/images/landing/for-seller/stripe-mobile.png'
import StripeSrc from 'app/assets/images/landing/for-seller/stripe.png'
import breakpoints from 'app/theme/common/breakpoints'

const StripeImage = () => {
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down(breakpoints.xxl),
    {
      noSsr: true
    }
  )

  const imageSrc = isMobile ? StripeMobileSrc : StripeSrc
  const imageWidth = isMobile ? 254 : 235
  const imageHeight = isMobile ? 80 : 252

  return (
    <Box
      sx={{
        position: 'absolute',
        width: { xs: 254, xxl: 235 },
        height: { xs: 80, xxl: 252 },
        right: { xxl: -10 },
        bottom: { xs: 0, xxl: -6 },
        display: { xs: 'block', lg: 'none', xl: 'block' }
      }}
    >
      <NextImage
        src={imageSrc}
        sizes="384px"
        layout="responsive"
        width={imageWidth}
        height={imageHeight}
        alt="stripe"
      />
    </Box>
  )
}

export default StripeImage
