import React, { useEffect } from 'react'

import { Box, BoxProps, NoSsr, useTheme } from '@mui/material'

import IphoneSVG from 'app/assets/images/landing/iphone-mockup.svg'

import { useVideoSliderContext } from './VideoSlider'

interface VideoItemProps {
  source: string
  sx?: BoxProps['sx']
}

const useStyles = () => {
  const theme = useTheme()
  return {
    item: {
      position: 'relative',
      svg: {
        position: 'relative',
        zIndex: 1,
        height: 'auto'
      },

      [theme.breakpoints.up('xs')]: {
        width: 280,
        top: 0,
        svg: {
          width: 280
        }
      },
      [theme.breakpoints.up('md')]: {
        width: 270,
        svg: {
          width: 270
        }
      },
      [theme.breakpoints.up('xl')]: {
        width: 330,
        svg: {
          width: 330
        }
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        width: 380,
        svg: {
          width: 380
        }
      }
    }
  }
}

const VideoItem: RFC<VideoItemProps> = ({ source, sx = {} }) => {
  const styles = useStyles()
  const { activeIndex } = useVideoSliderContext()
  const videoRef = React.useRef<HTMLVideoElement & { playing: boolean }>(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause()
      videoRef.current.currentTime = 0

      setTimeout(() => {
        handleVideoPlay()
      }, 300)
    }
  }, [activeIndex])

  const handleVideoPlay = () => {
    if (videoRef.current) {
      if (!videoRef.current?.playing) {
        videoRef.current.play()
      }
    }
  }

  return (
    <Box
      sx={{
        width: { xs: 265, md: 235, lg: 290, xxl: 335 },
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx
      }}
      onClick={handleVideoPlay}
    >
      {/* @ts-ignore */}
      <Box sx={styles.item}>
        <IphoneSVG />
      </Box>
      <NoSsr>
        <Box
          ref={videoRef}
          component="video"
          autoPlay
          muted
          playsInline
          loop
          sx={{
            position: 'absolute',
            width: '100%',
            height: '99%',
            maxHeight: '100%',
            left: 0,
            top: 0,
            padding: { xs: '10px', md: 0, lg: '16px', xl: 0 },
            borderRadius: { xs: 5, md: 1 },
            zIndex: -1
          }}
          src={source}
        />
      </NoSsr>
    </Box>
  )
}

export default VideoItem
