import React, { useEffect } from 'react'

import { IconButton } from '@mui/material'

import ArrowUpSVG from 'app/assets/icons/landing/arrow-up.svg'
import useBoolean from 'app/hooks/useBoolean'

const ScrollButton = () => {
  const [isVisible, handlers] = useBoolean()

  const handleVisible = () => {
    const scrolled = document.documentElement.scrollTop

    if (scrolled > 500) {
      handlers.on()
    } else if (scrolled <= 500) {
      handlers.off()
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleVisible, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleVisible)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  if (!isVisible) {
    return null
  }

  return (
    <IconButton
      onClick={scrollToTop}
      sx={{
        width: 50,
        height: 50,
        p: 0,
        position: 'fixed',
        bottom: 85,
        right: 12,
        zIndex: 3
      }}
    >
      <ArrowUpSVG />
    </IconButton>
  )
}

export default ScrollButton
