import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import Text from 'app/components/base/Text'
import ChipLabel from 'app/components/common/ChipLabel'

const BuyerChip = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <Fade duration={300} delay={400} ssrReveal>
      <ChipLabel
        startColor="background.default"
        endColor="background.default"
        sx={{
          borderRadius: 6,
          px: 2.75,
          py: 0.75,
          maxWidth: 138,
          alignSelf: 'center',
          zIndex: 1
        }}
      >
        <Text
          variant="h3"
          sx={{
            color: 'primary.main',
            fontWeight: 500,
            lineHeight: '28px'
          }}
        >
          For Buyer
        </Text>
      </ChipLabel>
    </Fade>
  </Box>
)

export default BuyerChip
