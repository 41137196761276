import NextImage from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import AvatarSrc from 'app/assets/images/landing/community/middle-left-avatar.png'

const MiddleAvatarLeft = () => (
  <Box
    sx={{
      position: 'absolute',
      display: { xs: 'none', lg: 'block' },
      bottom: 425,
      left: { lg: 16, xl: 24, xxl: 50 }
    }}
  >
    <Fade duration={300} delay={800} ssrReveal>
      <NextImage
        src={AvatarSrc}
        layout="intrinsic"
        sizes="96px"
        width={70}
        height={70}
        alt="middle-left-avatar"
      />
    </Fade>
  </Box>
)

export default MiddleAvatarLeft
