import React from 'react'

import { Box, Stack } from '@mui/material'

import BlueGradient from './components/BlueGradient'
import ButtonBlock from './components/ButtonBlock'
import PhoneImage from './components/PhoneImage'
import TextBlock from './components/TextBlock'

const GetStartedSection = () => {
  return (
    <Stack
      direction="column"
      spacing={4}
      sx={{
        px: { xs: 2, lg: 10, xl: 25 },
        pt: { xs: 6, md: 13.75 },
        pb: { xs: 13.75 },
        alignItems: 'center',
        overflow: 'hidden'
      }}
    >
      <TextBlock />

      <Stack direction="column" sx={{ alignItems: 'center' }}>
        <ButtonBlock />

        <Box sx={{ position: 'relative' }}>
          <BlueGradient />

          <PhoneImage />
        </Box>
      </Stack>
    </Stack>
  )
}

export default GetStartedSection
