import React from 'react'

import Fade from 'react-reveal/Fade'

import Text from 'app/components/base/Text'

const TextBlock = () => (
  <Fade duration={500} ssrReveal bottom distance="30px">
    <Text
      variant="h2"
      sx={{
        color: 'background.default',
        textAlign: 'center',
        fontSize: { xs: 36, lg: 64 },
        lineHeight: { xs: '49px', lg: '74px' },
        minWidth: { lg: 1150 },
        span: { display: 'block' }
      }}
    >
      Constantly Innovating <span>New Features Always in Development</span>
    </Text>
  </Fade>
)

export default TextBlock
