import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import GreenEclipse from '~/views/LandingView/components/GreenEclipse'

const BottomEclipseBlock = () => (
  <Box
    sx={{
      transform: 'rotate(-86.26deg) translateZ(1px)',
      width: 762,
      position: 'absolute',
      bottom: 0,
      height: 625,
      left: { xs: -420, xl: -250 },

      '.react-reveal': {
        width: '100%',
        height: '100%'
      }
    }}
  >
    <Fade duration={500} delay={1800} bottom ssrReveal distance="20px">
      <GreenEclipse
        sx={{
          width: '100%',
          height: '100%'
        }}
      />
    </Fade>
  </Box>
)

export default BottomEclipseBlock
