import React from 'react'

import Button from 'app/components/base/Button'
import Stack, { StackProps } from 'app/components/base/Stack'
import Text from 'app/components/base/Text'

interface SuccessBlockProps {
  onClick: () => void
  sx?: StackProps['sx']
}

const SuccessBlock: RFC<SuccessBlockProps> = ({ onClick, sx }) => {
  return (
    <Stack
      direction="column"
      spacing={3}
      sx={{
        py: { xs: 10, md: 22.5 },
        px: { xs: 4, sm: 0 },
        alignItems: 'center',
        ...sx
      }}
    >
      <Text variant="h2" sx={{ textAlign: 'center', color: 'primary.dark' }}>
        Thank you for signing up!
      </Text>

      <Button
        size="large"
        variant="contained"
        sx={{ flex: 1, width: 190 }}
        onClick={onClick}
      >
        Done
      </Button>
    </Stack>
  )
}

export default SuccessBlock
