import axios from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

interface IPInformationModel {
  ip: string
  country: string
  city?: string
  postal?: string
  loc?: string
  region?: string
  timezone?: string
  org?: string
}

const token = process.env.IP_INFO_TOKEN || ''

const ipAddressConfig = {
  getIPInfo: {
    key: 'getIPInfo',
    request: () =>
      axios.get(`https://ipinfo.io/json?token=${token}`).then((res) => res.data)
  }
}

interface UseGetIPInformationProps {
  options?: UseQueryOptions<IPInformationModel>
}

const useGetIPInformation = (props?: UseGetIPInformationProps) => {
  const { options } = props || {}
  const { getIPInfo: config } = ipAddressConfig

  const state = useQuery<IPInformationModel>(config.key, config.request, {
    ...options
  })

  return state
}

export default useGetIPInformation
