import { makeTheme } from 'dripsy'

import commonTheme from '../common/theme'

import { commonThemeToDripsy } from './dripsy.utils'

const dripsyTheme = commonThemeToDripsy(commonTheme)

const theme = makeTheme({
  // https://www.dripsy.xyz/usage/theming/create
  ...dripsyTheme,
  fonts: {
    root: dripsyTheme.fonts.Poppins,
    ...dripsyTheme.fonts
  },
  types: {
    onlyAllowThemeValues: 'never'
  },
  space: {},
  styles: {
    // Skeleton variants
    paper: {
      borderRadius: commonTheme.borderRadius
    },
    // Skeleton variants
    skeleton_circular: {
      borderRadius: 50
    },
    skeleton_rectangular: {},
    skeleton_text: {
      borderRadius: commonTheme.borderRadius,
      height: 24,
      transform: [{ scaleY: 0.6 }]
    }
  },
  forms: {
    textField: {
      height: 48,
      fontSize: 16,
      fontWeight: 300,
      lineHeight: 24
    },
    textFieldLabel: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: 24
    }
  }
})

export type DripsyTheme = typeof theme

export default theme
