import React from 'react'

import LazyHydrate from 'react-lazy-hydration'
import Fade from 'react-reveal/Fade'

import { Box, NoSsr, Stack, Theme, useMediaQuery } from '@mui/material'

import VideoSlider from '~/views/LandingView/components/VideoSlider'

import VideoItem from './VideoItem'

interface VideoBlockProps {
  type: ActionType
  videos: { source: string }[]
}

const Video = ({ type, videos }) => {
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    { noSsr: true }
  )

  return (
    <Stack sx={{ minHeight: 547 }}>
      {!isMobile && (
        <Stack
          direction="row"
          spacing={{ md: 6, lg: 10, xl: 15 }}
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            alignItems: 'center',
            mt: { md: 8 }
          }}
        >
          <NoSsr>
            {videos?.map((item) => (
              <VideoItem key={item.source} source={item.source} />
            ))}
          </NoSsr>
        </Stack>
      )}

      {isMobile && (
        <Stack sx={{ width: '100vw', maxWidth: 343, alignSelf: 'center' }}>
          <VideoSlider type={type}>
            {videos?.map((item) => (
              <NoSsr key={item.source}>
                <Box
                  sx={{
                    mx: 0
                  }}
                  key={item.source}
                >
                  <VideoItem source={item.source} sx={{ mx: 'auto' }} />
                </Box>
              </NoSsr>
            ))}
          </VideoSlider>
        </Stack>
      )}
    </Stack>
  )
}

const VideoBlock = ({ type, videos }: VideoBlockProps) => {
  return (
    <LazyHydrate whenVisible>
      <Fade duration={500} delay={1000} ssrReveal>
        <Video type={type} videos={videos} />
      </Fade>
    </LazyHydrate>
  )
}

export default VideoBlock
