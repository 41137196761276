import NextImage from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box, Stack } from '@mui/material'

import AutoBrandsSrc from 'app/assets/images/landing/for-buyer/auto-brands.png'
import SpecialisedAutoPartsSrc from 'app/assets/images/landing/for-buyer/specialised-auto-parts.png'

import AppCard from '~/views/LandingView/components/AppCard'

import ChatImage from './ChatImage'

const Cards = () => {
  return (
    <Stack
      direction={{ xs: 'column', lg: 'row' }}
      spacing={{ xs: 2, lg: 3.75 }}
      sx={{ alignItems: { xs: 'center' } }}
    >
      <AppCard
        title="Specialised Auto Parts Marketplace"
        description="We focus only on auto parts; bringing sellers and buyers together in one place."
        sx={{
          maxWidth: { xs: 487 },
          height: { lg: 650 },
          maxHeight: '100%'
        }}
        textStackSx={{ width: { xxl: '85%' } }}
      >
        <Box
          sx={{
            width: { xs: 245, sm: 345, md: 411 },
            position: 'relative',
            top: { xs: 6, lg: 0 }
          }}
        >
          <NextImage
            src={SpecialisedAutoPartsSrc}
            sizes="640px"
            layout="responsive"
            alt="find"
          />
        </Box>
      </AppCard>

      <Stack
        direction="column"
        spacing={{ xs: 2, lg: 3.75 }}
        sx={{ alignItems: { xs: 'center' } }}
      >
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          spacing={{ xs: 2, xxl: 3.75 }}
          sx={{ alignItems: 'center' }}
        >
          <AppCard
            title="My Garage"
            description="Save multiple vehicle details for quick and easy searching."
            sx={{
              pb: { xs: 12.75, sm: 16 },
              flex: { lg: 1, xl: 2 },
              maxWidth: { xs: 487, lg: '100%' }
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: { xs: -5, sm: 0 },
                bottom: { xs: -40, sm: -8 },
                width: { xs: 600, sm: '100%' }
              }}
            >
              <NextImage
                src={AutoBrandsSrc}
                sizes="640px"
                layout="responsive"
                alt="find"
              />
            </Box>
          </AppCard>

          <AppCard
            title="Click & Collect"
            description="Want something today? Search your local click & collect sellers."
            sx={{
              py: { xs: 7.5, lg: 4 },
              maxWidth: { xs: 487, lg: 357 },
              width: { lg: '100%' },
              minWidth: { xxl: 357 },
              flex: { lg: 1 }
            }}
          />
        </Stack>

        <Stack
          direction={{ xs: 'column', lg: 'row-reverse' }}
          spacing={{ xs: 2, xxl: 3.75 }}
          sx={{ width: '100%' }}
        >
          <AppCard
            title="Always connected"
            description="Speak with sellers in real time."
            sx={{
              pb: { xs: 13.5, xxl: 12.5 },
              py: { lg: 6 },
              pt: { xs: 4, xxl: 10.75 },
              flex: { lg: 1, xl: 2 },
              maxWidth: { xs: 487, lg: '100%' }
            }}
            textStackSx={{ maxWidth: { xxl: 206 } }}
            contentDirection={{ xs: 'column', xxl: 'row' }}
          >
            <Stack
              direction="column"
              spacing={2}
              sx={{ width: { xs: 200, xxl: 261 }, ml: { xxl: 4 } }}
            >
              <ChatImage />
            </Stack>
          </AppCard>

          <AppCard
            title="Match Me"
            description="If you can’t find it now, we’ll match you with a seller later!"
            sx={{
              pb: { xs: 6, xxl: 12.5 },
              pt: { xs: 6, xxl: 11.25 },
              maxWidth: { xs: 487, lg: 357 },
              width: { lg: '100%' },
              minWidth: { xxl: 357 },
              flex: { lg: 1 }
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

const ForBuyerCards = () => {
  return (
    <Fade duration={500} delay={200} bottom ssrReveal distance="20px">
      <Cards />
    </Fade>
  )
}

export default ForBuyerCards
