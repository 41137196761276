import React from 'react'

import { Box, Stack } from '@mui/material'

import AdvantageIcon from 'app/assets/icons/landing/about-app-advantage.svg'
import Text from 'app/components/base/Text'

interface AdvantageBlockProps {
  title: string
  description: string
}

const AdvantageBlock: RFC<AdvantageBlockProps> = ({ title, description }) => {
  return (
    <Stack direction="column" spacing={{ xs: 0.75, md: 0 }}>
      <Stack direction="row" spacing={1.5} sx={{ alignItems: 'center' }}>
        <Box sx={{ width: 24, height: 24 }}>
          <AdvantageIcon />
        </Box>

        <Text
          variant="body1"
          sx={{
            fontWeight: 400,
            fontSize: { xs: 20, xxl: 24 },
            lineHeight: { xs: '27px', xxl: '34px' }
          }}
        >
          {title}
        </Text>
      </Stack>

      <Text
        variant="body1"
        sx={{
          color: 'text.third',
          fontSize: { xs: 16, xxl: 20 },
          lineHeight: { xs: '24px', xxl: '32px' }
        }}
      >
        {description}
      </Text>
    </Stack>
  )
}

export default AdvantageBlock
