import React from 'react'

import { Box, Theme, useMediaQuery } from '@mui/material'

import AuctionMobileSVG from 'app/assets/images/landing/for-seller/auction-mobile.svg'
import AuctionsSVG from 'app/assets/images/landing/for-seller/auctions.svg'

const AuctionImage = () => {
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('sm'),
    {
      noSsr: true
    }
  )

  return (
    <Box
      sx={{
        position: 'absolute',
        left: { sm: -20, xl: 0 },
        bottom: {
          xs: -8,
          sm: -25,
          md: -50,
          lg: -60,
          xl: -32,
          xxl: -8
        },
        width: { sm: '100%' }
      }}
    >
      {isMobile ? <AuctionMobileSVG /> : <AuctionsSVG />}
    </Box>
  )
}

export default AuctionImage
