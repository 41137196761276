import NextImage from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import PhoneRightSrc from 'app/assets/images/landing/hero-phone-right.png'

const PhoneRight = () => (
  <Box
    sx={{
      position: 'absolute',
      width: { xs: 220, md: 250, xxl: 315 },
      top: { xs: 0, md: -85, lg: -40, xl: 15, xxl: 5 },
      left: { xs: 150, md: 250, lg: 285, xl: 250, xxl: 485 },
      height: { xs: 409, md: 580 }
    }}
  >
    <Fade duration={700} delay={1200} top ssrReveal distance="50px">
      <NextImage
        src={PhoneRightSrc}
        layout="intrinsic"
        sizes="400px"
        width={315}
        height={580}
        priority
        alt="phone"
      />
    </Fade>
  </Box>
)

export default PhoneRight
