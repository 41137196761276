import NextImage from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import AvatarSrc from 'app/assets/images/landing/community/small-right-avatar.png'

const SmallAvatarRight = () => (
  <Box
    sx={{
      position: 'absolute',
      display: { xs: 'none', lg: 'block' },
      bottom: 379,
      height: 70,
      right: { lg: 66, xl: 100 }
    }}
  >
    <Fade duration={300} delay={1100} ssrReveal>
      <NextImage
        src={AvatarSrc}
        layout="intrinsic"
        sizes="96px"
        width={70}
        height={70}
        alt="small-avatar-left"
      />
    </Fade>
  </Box>
)

export default SmallAvatarRight
