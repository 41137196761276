import { ReactElement, ReactNode, ReactText, forwardRef, memo } from 'react'

import FormControl, { FormControlProps } from 'app/components/base/FormControl'
import useFormField, { UseFormFieldProps } from 'app/hooks/useFormField'
import { combineControllerProps } from 'app/utils/helpers/form.helpers'

import PhoneNumberInput, {
  PhoneNumberInputProps
} from '../base/PhoneNumberInput'

export interface PhoneNumberCustomProps extends UseFormFieldProps {
  helperText?: ReactText | ReactElement | ReactNode | null
  formControlProps?: FormControlProps
}

export type PhoneNumberFieldProps = PhoneNumberCustomProps &
  PhoneNumberInputProps

const PhoneNumberField = (
  { id, helperText, label, formControlProps, ...props }: PhoneNumberFieldProps,
  ref: any
) => {
  const { controllerProps, ...rest } = combineControllerProps(props)
  const { field, error, onChange, value } = useFormField(controllerProps)
  return (
    <FormControl
      errorMessage={error}
      helperText={helperText}
      {...formControlProps}
    >
      <PhoneNumberInput
        {...field}
        ref={ref}
        error={!!error}
        label={label}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </FormControl>
  )
}

export default memo(forwardRef(PhoneNumberField))
