import NextImage from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import AvatarSrc from 'app/assets/images/landing/community/small-bottom-avatar.png'

const SmallAvatarBottom = () => (
  <Box
    sx={{
      position: 'absolute',
      display: { xs: 'none', lg: 'block' },
      bottom: 238,
      right: { lg: 230, xl: 275 }
    }}
  >
    <Fade duration={300} delay={800} ssrReveal>
      <NextImage
        src={AvatarSrc}
        layout="intrinsic"
        sizes="96px"
        width={94}
        height={94}
        alt="small-bottom-avatar"
      />
    </Fade>
  </Box>
)

export default SmallAvatarBottom
