import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import GreenEclipse from '~/views/LandingView/components/GreenEclipse'

const TopEclipseBlock = () => (
  <Box
    sx={{
      width: 577,
      height: 176,
      position: 'absolute',
      top: -100,
      transform: 'translateZ(1px)',

      '.react-reveal': {
        width: '100%',
        height: '100%'
      }
    }}
  >
    <Fade duration={500} ssrReveal>
      <GreenEclipse
        sx={{
          width: '100%',
          height: '100%'
        }}
      />
    </Fade>
  </Box>
)

export default TopEclipseBlock
