import React from 'react'

import { Stack } from '@mui/material'

import AboutAppBlock from './components/AboutAppBlock'
import FormBlock from './components/FormBlock'

const useStyles = () => {
  return {
    root: { justifyContent: { xxl: 'center' } },
    stack: {
      px: { xs: 2, lg: 10, xxl: 25 },
      pt: { xs: 6, md: 18.75 },
      pb: { xs: 2, md: 18.75 },
      justifyContent: { md: 'center', xl: 'space-between' },
      maxWidth: { xxl: 1920 },
      width: '100%',
      alignItems: { md: 'center', xl: 'initial', xxl: 'center' }
    }
  }
}

const AboutAppSection = () => {
  const styles = useStyles()

  return (
    <Stack id="aboutTheApp" direction="row" sx={styles.root}>
      <Stack
        direction={{ xs: 'column-reverse', md: 'row' }}
        spacing={{ xs: 6, md: 6, xl: 12, xxl: 6.25 }}
        sx={styles.stack}
      >
        <FormBlock />

        <AboutAppBlock />
      </Stack>
    </Stack>
  )
}

export default AboutAppSection
