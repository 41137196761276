import { GetStaticProps } from 'next'
import React, { ReactElement } from 'react'

import { dehydrate, QueryClient } from 'react-query'

import { userConfig } from 'app/services/Query/user/user.query'
import { NextPage } from 'app/types/decalarations/next'

import LandingLayout from '~/components/layouts/LandingLayout'
import LandingView from '~/views/LandingView'
import SignUpModalProvider from '~/views/LandingView/providers/SignUpModalProvider'

const LandingPage: NextPage = () => <LandingView />

LandingPage.getLayout = (page: ReactElement) => (
  <SignUpModalProvider>
    <LandingLayout>{page}</LandingLayout>
  </SignUpModalProvider>
)

LandingPage.getMeta = (intl) => ({
  title: intl.formatMessage({
    defaultMessage: 'Coming Soon'
  }),
  description: intl.formatMessage({
    defaultMessage: 'Coming Soon'
  })
})

export const getStaticProps: GetStaticProps = async () => {
  const revalidateInterval = 3600

  try {
    const queryClient = new QueryClient()

    await queryClient.prefetchQuery(
      userConfig.getVideos.key,
      userConfig.getVideos.request
    )

    return {
      props: {
        dehydratedState: dehydrate(queryClient)
      },
      revalidate: revalidateInterval
    }
  } catch (error) {
    return { notFound: false, props: {}, revalidate: revalidateInterval }
  }
}

export default LandingPage
