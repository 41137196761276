import React from 'react'

import { Box } from '@mui/material'

import EclipseSvg from 'app/assets/images/landing/hero-eclipse.svg'

type Props = {}

const HeroGradient = React.memo((props: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <EclipseSvg />
    </Box>
  )
})

export default HeroGradient
