import { isOfType } from 'app/utils/helpers/type.helpers'

import { CommonTheme } from '../common/theme'

type MappedPxToNumber<Type> = {
  [Property in keyof Type]: Type[Property] extends object
    ? MappedPxToNumber<Type[Property]>
    : Type[Property] extends string
    ? Type[Property] extends `${Type[Property]}px`
      ? number
      : Type[Property]
    : Type[Property]
}

const transPxToNumber = <T>(m: T): MappedPxToNumber<T> => {
  const r = {}

  Object.keys(m as any).forEach((k) => {
    const v = m[k]
    if (isOfType.object(v)) {
      r[k] = transPxToNumber(v)
    } else if (isOfType.string(v) && v.endsWith('px')) {
      r[k] = Number.parseInt(v)
    } else {
      r[k] = v
    }
  })

  return r as MappedPxToNumber<T>
}

type Flatten<T extends object> = object extends T
  ? object
  : {
      [K in keyof T]-?: (
        x: NonNullable<T[K]> extends infer V
          ? V extends object
            ? V extends readonly any[]
              ? Pick<T, K>
              : Flatten<V> extends infer FV
              ? {
                  [P in keyof FV as `${Extract<K, string | number>}.${Extract<
                    P,
                    string | number
                  >}`]: FV[P]
                }
              : never
            : Pick<T, K>
          : never
      ) => void
    } extends Record<keyof T, (y: infer O) => void>
  ? O extends infer U
    ? { [K in keyof O]: O[K] }
    : never
  : never

export function flatten<T extends Record<string, any>>(
  object: T,
  path: string | null = null,
  separator = '.'
): Flatten<T> {
  return Object.keys(object).reduce(
    (acc: Flatten<T>, key: string): Flatten<T> => {
      const value = object[key]

      const newPath = [path, key].filter(Boolean).join(separator)

      const isObject = [
        typeof value === 'object',
        value !== null,
        !(value instanceof Date),
        !(value instanceof RegExp),
        !(Array.isArray(value) && value.length === 0)
      ].every(Boolean)

      return isObject
        ? { ...acc, ...flatten(value, newPath, separator) }
        : { ...acc, [newPath]: value }
    },
    {} as Flatten<T>
  )
}

type ObjectFonts<
  Type extends { [string: string]: { name: string; [string: string]: any } }
> = {
  [Property in keyof Type as `${Type[Property]['name']}`]: Type[Property]['name']
}

export const commonThemeToDripsy = (theme: CommonTheme) => {
  const text = transPxToNumber(theme.typography)

  const fontSizes = Object.entries(text).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value.fontSize
    }),
    {}
  ) as Record<keyof typeof text, typeof text[keyof typeof text]['fontSize']>

  const colors = flatten(theme.palette)

  const breakpoints = Object.values(theme.breakpoints).map(
    (value) => `${value}px`
  )

  const customFonts = Object.values(theme.fonts).reduce(
    (acc, value) => ({
      ...acc,
      [value.name]: value.weight
    }),
    {}
  ) as Record<
    typeof theme.fonts[keyof typeof theme.fonts]['name'],
    Record<keyof typeof theme.fonts[keyof typeof theme.fonts]['weight'], string>
  >

  const fonts = Object.values(theme.fonts).reduce(
    (acc, value) => ({
      ...acc,
      [value.name]: value.name
    }),
    {}
  ) as ObjectFonts<typeof theme.fonts>

  return {
    fonts,
    customFonts,
    text,
    fontSizes,
    colors,
    breakpoints
  }
}
