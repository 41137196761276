import React from 'react'

import Fade from 'react-reveal/Fade'

import Text from 'app/components/base/Text'

const useStyles = () => {
  return {
    root: {
      color: 'background.default',
      textAlign: { xs: 'center', md: 'start' },
      fontSize: { xs: 18, xxl: 24 },
      lineHeight: { xs: '27px', xxl: '34px' }
    }
  }
}

const DescriptionBlock = () => {
  const styles = useStyles()

  return (
    <Fade duration={500} delay={1500} bottom ssrReveal distance="30px">
      <Text variant="h4" sx={styles.root}>
        We’ve changed the way you buy and sell auto parts. A marketplace app
        dedicated to the world of car parts. Register your interest now to learn
        more.
      </Text>
    </Fade>
  )
}

export default DescriptionBlock
