import React from 'react'

import { Box, NoSsr, Stack, useTheme } from '@mui/material'

import ArrowDown from './components/ArrowDown'
import ButtonBlock from './components/ButtonBlock'
import DescriptionBlock from './components/DescriptionBlock'
import Eclipse from './components/Eclipse'
import IfndImage from './components/IfndImage'
import PhoneLeft from './components/PhoneLeft'
import PhoneRight from './components/PhoneRight'
import TitleBlock from './components/TitleBlock'

const useStyles = () => {
  const { palette } = useTheme()

  return {
    root: {
      background: `linear-gradient(180deg, ${palette.primary.main} 0%, ${palette.primary.dark} 100%)`,
      minHeight: '100vh',
      paddingTop: '132px',
      display: 'flex',
      overflow: 'hidden',
      justifyContent: { xxl: 'center' }
    },
    wrapperStack: {
      flex: 1,
      px: { xs: 2, lg: 10, xxl: 25 },
      pt: { xs: 6, md: 0, lg: 5, xl: 3 },
      pb: { xs: 4.75, md: 0 },
      alignItems: { xs: 'center' },
      justifyContent: { md: 'space-between' },
      maxWidth: { xxl: 1920 },
      width: { xxl: '100%' }
    },
    startBlock: {
      alignItems: { xs: 'center', md: 'start' },
      maxWidth: { xs: '100%', md: 400, lg: 487 },
      minWidth: { xl: 487 }
    },
    endBlock: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: { xs: 343, md: 520, lg: 645, xl: 700, xxl: 975 },
      height: { xs: 300, md: 400, lg: 500, xl: 600, xxl: 759 }
    },
    buttonBox: {
      display: { xs: 'none', md: 'block' }
    },
    mobileButtonBox: {
      display: { xs: 'block', md: 'none' },
      mt: 20
    }
  }
}

const HeroSection = () => {
  const styles = useStyles()

  return (
    <Box sx={styles.root}>
      <Stack direction={{ xs: 'column', md: 'row' }} sx={styles.wrapperStack}>
        <Stack direction="column" spacing={4} sx={styles.startBlock}>
          <Stack direction="column" spacing={2}>
            <TitleBlock />

            <DescriptionBlock />
          </Stack>

          <Box sx={styles.buttonBox}>
            <NoSsr>
              <ButtonBlock />
            </NoSsr>
          </Box>
        </Stack>

        <Box sx={styles.endBlock}>
          <Eclipse />

          <IfndImage />

          <PhoneLeft />

          <PhoneRight />
        </Box>

        <Box id="mobile-button-block" sx={styles.mobileButtonBox} />
      </Stack>

      <ArrowDown />
    </Box>
  )
}

export default HeroSection
