import NextImage from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box } from '@mui/material'

import AvatarSrc from 'app/assets/images/landing/community/bottom-avatar-mobile.png'

const BottomAvatarMobile = () => (
  <Box
    sx={{
      position: 'absolute',
      bottom: 12,
      left: 32,
      display: { xs: 'block', lg: 'none' }
    }}
  >
    <Fade duration={300} delay={500} ssrReveal>
      <NextImage
        src={AvatarSrc}
        layout="intrinsic"
        sizes="96px"
        width={80}
        height={80}
        alt="avatar"
      />
    </Fade>
  </Box>
)

export default BottomAvatarMobile
