import Image from 'next/image'
import React from 'react'

import Fade from 'react-reveal/Fade'

import { Box, Paper, Stack } from '@mui/material'

import SignUpCarSrc from 'app/assets/images/landing/about-app/sign-up-car.png'
import TriangleBottomSVG from 'app/assets/images/landing/about-app/triangle-bottom.svg'
import TriangleTopSVG from 'app/assets/images/landing/about-app/triangle-top.svg'

import GreenEclipse from '~/views/LandingView/components/GreenEclipse'
import SignUpForm from '~/views/LandingView/components/SignUpForm'

import ContactUsBlock from './ContactUsBlock'

const useStyles = () => {
  return {
    triangleTop: {
      display: { xs: 'block', md: 'none' },
      position: 'absolute',
      top: -30,
      right: -16
    },
    triangleBottom: {
      display: { xs: 'block', md: 'none' },
      position: 'absolute',
      bottom: 0,
      left: -16
    },
    paper: {
      minWidth: { md: 450, xl: 616 },
      borderWidth: { xs: 0, md: 1 },
      borderColor: 'stroke.main',
      borderStyle: 'solid',
      borderRadius: 3,
      px: { md: 6, xl: 8, xxl: 14 },
      pt: { md: 6, xl: 6 },
      pb: { md: 28.5, xl: 28.5 },
      position: 'relative',
      overflow: { md: 'hidden' }
    },
    eclipse: {
      display: { xs: 'none', md: 'block' },
      width: 577,
      height: 176,
      position: 'absolute',
      top: -180,
      right: -150
    },
    imageWrapper: {
      width: 329,
      position: 'absolute',
      bottom: -8,
      left: 0,
      display: { xs: 'none', md: 'block' }
    }
  }
}

const FormFragment = React.memo(() => {
  const styles = useStyles()

  return (
    <>
      <Box sx={styles.triangleTop}>
        <TriangleTopSVG />
      </Box>

      <Box sx={styles.triangleBottom}>
        <TriangleBottomSVG />
      </Box>

      <Paper elevation={0} sx={styles.paper}>
        <GreenEclipse sx={styles.eclipse} />

        <SignUpForm />

        <Box sx={styles.imageWrapper}>
          <Image src={SignUpCarSrc} alt="car" />
        </Box>
      </Paper>

      <ContactUsBlock />
    </>
  )
})

const FormBlock = () => (
  <Fade duration={500} bottom ssrReveal distance="30px">
    <Stack
      direction="column"
      sx={{ alignItems: 'center', position: 'relative' }}
    >
      <FormFragment />
    </Stack>
  </Fade>
)

export default FormBlock
