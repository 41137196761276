import React from 'react'

import { Theme, useMediaQuery } from '@mui/material'

import ChatBubbleGetPartSrc from 'app/assets/images/landing/community/chat-bubble-get-part.png'
import ChatBubbleGetItemSrc from 'app/assets/images/landing/community/chat-bubble-item.png'
import ChatBubbleMechanicSrc from 'app/assets/images/landing/community/chat-bubble-mechanic.png'
import ChatBubbleSolutionSrc from 'app/assets/images/landing/community/chat-bubble-solution.png'

import BigAvatarBottom from './BigAvatarBottom'
import BigAvatarLeft from './BigAvatarLeft'
import BigAvatarRight from './BigAvatarRight'
import BigAvatarTop from './BigAvatarTop'
import BottomAvatarMobile from './BottomAvatarMobile'
import ChatBubble from './ChatBubble'
import MiddleAvatarLeft from './MiddleAvatarLeft'
import SmallAvatarBottom from './SmallAvatarBottom'
import SmallAvatarRight from './SmallAvatarRight'
import SmallAvatarTop from './SmallAvatarTop'
import TopAvatarMobile from './TopAvatarMobile'

const ImagesBlock = () => {
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('lg'),
    { noSsr: true }
  )

  const bubbleGetItemDelay = isMobile ? '0.8s' : '1.4s'
  const bubbleSolutionDelay = isMobile ? '0.8s' : '1.9s'

  return (
    <>
      <ChatBubble
        src={ChatBubbleGetItemSrc}
        width={295}
        height={48}
        sx={{
          top: { xs: 43, lg: 242 },
          right: { xs: 68, lg: 0 },
          left: { lg: 230, xl: 270, xxl: 370 },
          width: 295
        }}
        delay={bubbleGetItemDelay}
      />

      <ChatBubble
        src={ChatBubbleSolutionSrc}
        width={281}
        height={66}
        sx={{
          width: 281,
          height: 66,
          bottom: { xs: 112, lg: 0 },
          left: { xs: 60, lg: 'auto' },
          top: { lg: 345 },
          right: { lg: 100, xl: 178 }
        }}
        delay={bubbleSolutionDelay}
      />

      {isMobile && (
        <>
          <TopAvatarMobile />

          <BottomAvatarMobile />
        </>
      )}

      {!isMobile && (
        <>
          <SmallAvatarTop />

          <BigAvatarTop />

          <BigAvatarLeft />

          <MiddleAvatarLeft />

          <BigAvatarRight />

          <SmallAvatarRight />

          <BigAvatarBottom />

          <ChatBubble
            src={ChatBubbleGetPartSrc}
            width={297}
            height={66}
            sx={{
              position: 'absolute',
              display: { xs: 'none', lg: 'block' },
              bottom: 360,
              left: { lg: 366, xl: 355 }
            }}
            delay="1.9s"
          />

          <SmallAvatarBottom />

          <ChatBubble
            src={ChatBubbleMechanicSrc}
            width={248}
            height={67}
            sx={{
              position: 'absolute',
              display: { xs: 'none', lg: 'block' },
              bottom: 257,
              right: { lg: 340, xl: 387 }
            }}
            delay="1.4s"
          />
        </>
      )}
    </>
  )
}

export default ImagesBlock
