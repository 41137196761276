import React from 'react'

import Fade from 'react-reveal/Fade'

import { Stack } from '@mui/material'

import Text from 'app/components/base/Text'

const GetStartedText = () => (
  <Stack
    direction="column"
    spacing={3}
    sx={{
      width: '100%',
      maxWidth: { xs: 487, lg: '100%' },
      alignItems: 'center'
    }}
  >
    <Text
      variant="h2"
      sx={{
        textAlign: 'center',
        fontSize: { xs: 36, lg: 64 },
        lineHeight: { xs: '49px', lg: '74px' },
        span: { display: { lg: 'block', xxl: 'inline' } }
      }}
    >
      You want it? You need it? <span>IFND it today!</span>
    </Text>

    <Text
      variant="body1"
      sx={{
        textAlign: 'center',
        fontSize: { xs: 18, lg: 20 },
        lineHeight: { xs: '27px', lg: '30px' },
        maxWidth: { lg: 700 },
        b: { fontWeight: 500 },
        span: { display: 'block' }
      }}
    >
      It’s <b>FREE</b> to Join, so Register now to get in first. We offer
      exclusive rewards, discounts, and early-bird offers when you join us.
      <span>See you there!</span>
    </Text>
  </Stack>
)

const TextBlock = () => {
  return (
    <Fade duration={300} bottom distance="20px" ssrReveal>
      <GetStartedText />
    </Fade>
  )
}

export default TextBlock
