import React, { useState } from 'react'

import ReactDOM from 'react-dom'
import Fade from 'react-reveal/Fade'

import { Theme, useMediaQuery } from '@mui/material'

import { IS_BROWSER } from 'app/utils/constants/env.constants'

import SignUpButton from '~/views/LandingView/components/SignUpButton'

export const createPortalRef = ({ id = 'portal-ref' }) => {
  if (!IS_BROWSER) {
    return null
  }

  let modalRoot = document.querySelector(`#${id}`)

  if (!modalRoot) {
    modalRoot = document.createElement('div')
    modalRoot.setAttribute('id', id)
    document.body.appendChild(modalRoot)
  }

  return modalRoot
}

const MemoButton = React.memo(() => (
  <SignUpButton
    buttonProps={{
      size: 'large',
      sx: {
        minHeight: 54,
        width: { xs: 280, md: 216 }
      }
    }}
  />
))

const Button = () => (
  <Fade duration={500} delay={1800} bottom>
    <MemoButton />
  </Fade>
)

const ButtonBlock = () => {
  const isMdWidth = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )
  const [ref] = useState(() => createPortalRef({ id: 'mobile-button-block' }))

  if (isMdWidth) {
    return ReactDOM.createPortal(<Button />, ref as Element)
  }

  return <Button />
}

export default ButtonBlock
