import Stack, { StackProps } from 'app/components/base/Stack'
import theme from 'app/theme/dripsy'

export interface ChipLabelProps {
  startColor?: string
  endColor?: string
  sx?: StackProps['sx']
}

const Chip: RFC<ChipLabelProps> = ({
  startColor = 'secondary.light',
  endColor = 'secondary.main',
  sx,
  children
}) => {
  const colorFrom = theme.colors[startColor]
  const colorTo = theme.colors[endColor]

  return (
    <Stack
      sx={{
        borderRadius: '10px',
        justifyContent: 'center',
        background: `linear-gradient(90deg, ${colorFrom} 0%, ${colorTo} 100%)`,
        ...sx
      }}
    >
      {children}
    </Stack>
  )
}

export default Chip
